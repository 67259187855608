@keyframes pulse {
    0% {
        opacity: 0.3; /* Transparansi tipis */
    }
    50% {
        opacity: 1; /* Transparansi normal */
    }
    100% {
        opacity: 0.3; /* Transparansi tipis */
    }
}

.animated-circle {
    animation: pulse 3s infinite ease-in-out; /* Tambahkan ease-in-out untuk transisi yang lebih halus */
}
