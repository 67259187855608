/* App.css */

html, body {
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.homepage-container {
    max-width: 100vw;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
}

.homepage-content {
    background-color: #FFFFFF;
    border-radius: 10px 10px 0 0;
    max-height: 95vh;
    overflow-y: auto;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
}

.container {
    width: 100%;
    margin: 0;
    padding: 0;
}

.row {
    margin: 0;
    padding: 0;
}

.col-md-12 {
    width: 100%;
}
